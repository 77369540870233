import React, { type ReactNode } from "react";
import panther from "./Icons/panther.png";
import confettiReaction from "./CelebrationNotification/animations/confetti-reaction.json";
import fireReaction from "./CelebrationNotification/animations/fire-reaction.json";
import heartsReaction from "./CelebrationNotification/animations/hearts-reaction.json";
import pantherReaction from "./CelebrationNotification/animations/panther-reaction.json";

export interface BaseNotificationType {
    type: "SaleMadeNotification" | "Celebration";
    id: string;
}
export type Mascot = "Panthers" | "Raptors" | "Turtles" | "Bears" | "Mustangs" | "Hawks" | "Tigers";

export type Reaction = "Confetti" | "Fire" | "Hearts" | Mascot;

const MascotToReactionMap: Record<Mascot, { icon: ReactNode; animation: unknown }> = {
    Panthers: {
        icon: <img src={panther} alt="panther" height={32} width={32} />,
        animation: pantherReaction,
    },
    Raptors: {
        icon: undefined,
        animation: undefined,
    },
    Turtles: {
        icon: undefined,
        animation: undefined,
    },
    Bears: {
        icon: undefined,
        animation: undefined,
    },
    Mustangs: {
        icon: undefined,
        animation: undefined,
    },
    Hawks: {
        icon: undefined,
        animation: undefined,
    },
    Tigers: {
        icon: undefined,
        animation: undefined,
    },
};

export interface SaleMadeNotificationType extends BaseNotificationType {
    props: {
        seller: {
            employeeName: string;
            employeeId: string;
            rank: number;
            teamSize: number;
            mascot: Mascot;
        };
    };
}

export interface CelebrationNotificationType extends BaseNotificationType {
    props: {
        sender: string;
        reactionType: Reaction;
    };
}

interface ApprovedReactions {
    id: Reaction;
    value: string | ReactNode;
    animationData: unknown;
}

export const getApprovedReactions = (mascot: Mascot | Reaction | null): ApprovedReactions[] => {
    const reactions: ApprovedReactions[] = [
        { id: "Confetti", value: "🎉", animationData: confettiReaction },
        { id: "Fire", value: "🔥", animationData: fireReaction },
        { id: "Hearts", value: "❤️", animationData: heartsReaction },
    ];

    const mascotReaction = MascotToReactionMap[mascot as Mascot];
    if (mascotReaction.icon && mascotReaction.animation)
        reactions.push({
            id: mascot as Mascot,
            value: mascotReaction.icon,
            animationData: mascotReaction.animation,
        });

    return reactions;
};
