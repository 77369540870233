import type { TimelineFeatures } from "@expert/features";

export const features: TimelineFeatures = {
    autoCompleteDelay: 500,
    isAutoCompleteEnabled: false,
    isHelpMeNowEnabled: false,
    isMessageRatingEnabled: false,
    isMultiModalEnabled: false,
    isOzmoMessagingEnabled: false,
    isOzmoToolEnabled: false,
    isProactiveEnabled: false,
    isReactiveCallContextEnabled: false,
    isSalesFAQEnabled: false,
};
