import { Card, Flex } from "@mantine/core";
import classes from "../PayDashboard.module.css";
import { formatUpdateTime } from "../utils";

interface UpdatedTimeProps {
    updatedTime?: string;
}

export function UpdatedTime({ updatedTime }: UpdatedTimeProps): JSX.Element {
    const updateTime = updatedTime ? formatUpdateTime(updatedTime) : " coming soon";
    return (
        <>
            <Flex pr={4} align="center">
                <Card p="0" w="8px" h="8px" className={classes.dot} radius="xl" />
            </Flex>
            {updatedTime ? <strong>Last updated:</strong> : <strong>Next update:</strong>}
            <Flex pl={4}>{updateTime}</Flex>
        </>
    );
}
