import { type TimelineFeatures, useFeatures } from "@expert/features";
import { HelpMeNowButton, NewMessageButton } from "@expert/proactive-notifications";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useEffect, useMemo, useState } from "react";
import { type ExpertAssistMessage } from "../../shared-types";
import { useTimelineStore } from "../../state";
import { isFromBot, useAutoscroll } from "../../utils";
import { List, TimelineListWrapper } from "./elements";
import { generateTimelineItems } from "./generateTimelineItems";
import type { TimelineListProps } from "./types";
import { useScrollHandler } from "./useScrollHandler";

export function TimelineList({ logger, sessionId, scrollRef, isFocused }: TimelineListProps) {
    const { dispatcher } = useReactAnalytics();
    const { messages } = useTimelineStore();
    const {
        features: { isHelpMeNowEnabled },
    } = useFeatures<TimelineFeatures>();
    const { start: startAutoscroll } = useAutoscroll(scrollRef);
    const { mergedRef, isScrollAtBottom } = useScrollHandler({ scrollRef, messages: messages.length });
    const [shouldShowNewMessageButton, setShouldShowNewMessageButton] = useState(false);

    const timelineItems = useMemo(
        () =>
            generateTimelineItems({
                logger,
                messages,
                scrollRef,
                isFocused,
            }),
        [messages, scrollRef, isFocused, logger],
    );

    const unreadMessages = useMemo(() => messages.filter((message) => message.isUnread).length, [messages]);
    const mostRecentMessage = messages[messages.length - 1] as ExpertAssistMessage | undefined;
    const isLoadingBotMessage = !isFromBot(mostRecentMessage?.type ?? "Default");

    const onNewMessageClick = () => {
        logger.info("New message button clicked");
        void dispatcher.dispatchUserAction("Click", "NewMessage", { sessionId });
        startAutoscroll();
    };

    const onHelpMeNowClick = () => {
        logger.info("Help me now button clicked");
        void dispatcher.dispatchUserAction("Click", "HelpMeNow", { sessionId });
        // TODO: fetch bot response with live call context
    };

    useEffect(() => {
        setShouldShowNewMessageButton(unreadMessages > 0 && !isScrollAtBottom);
    }, [unreadMessages, isScrollAtBottom]);

    // reset the animation for session resets
    useEffect(() => {
        const animatedElement = document.getElementById("timeline-list-wrapper");
        if (animatedElement) {
            animatedElement.style.animation = "none";
            animatedElement.offsetHeight;
            animatedElement.style.animation = "ease-out 1s slide-in 1";
        }
    }, [sessionId]);

    return (
        <TimelineListWrapper data-testid="timeline-list-wrapper" mergedRef={mergedRef}>
            <List data-testid="timeline-list">{timelineItems}</List>
            <NewMessageButton
                shouldShow={shouldShowNewMessageButton}
                num={unreadMessages}
                onClick={onNewMessageClick}
            />
            <HelpMeNowButton
                shouldShow={isHelpMeNowEnabled}
                isLoading={isLoadingBotMessage}
                onClick={onHelpMeNowClick}
            />
        </TimelineListWrapper>
    );
}
