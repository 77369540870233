import { Card, Divider, Flex, ScrollArea, Skeleton } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Reorder } from "framer-motion";
import { useSalesLeaderboard } from "../hooks/useSalesLeaderboard";
import { getCurrentWeekRange } from "../utils/getCurrentWeekRange";
import { expertWorkspaceWebSocketEventBus } from "../../../websocket";
import { userCache } from "../../../shared-utils";
import classes from "./LeaderBoardWidget.module.css";

export function LeaderBoardWidget(): JSX.Element {
    const { data: leaderBoardData, isLoading: isLoadingLeaderBoard } = useSalesLeaderboard();
    const queryClient = useQueryClient();
    useEffect(() => {
        const subscription = expertWorkspaceWebSocketEventBus.on("ws_sales-event", (payload) => {
            void queryClient.setQueryData(["sales-leaderboard"], payload);
        });

        return () => {
            subscription();
        };
    }, [queryClient]);
    const splitName = (name: string) => {
        const names = name.split(" ");
        if (!names[1]) return names[0];
        return `${names[0]} ${names[1].charAt(0)}.`;
    };

    return (
        <Flex className={classes.main}>
            <Flex direction="column" p="16px 16px 12px 16px">
                <Flex fw={700} fz={16} pb={2} className={classes.header}>
                    Team leaderboard
                </Flex>
                <Flex fw={400} fz={14} lh="16px" pb={6} c="dark.1">
                    Total VHDP enrolls
                </Flex>
                <Flex c="dark.2" fz={14} pb={12}>
                    Remember to focus on quality sales
                </Flex>
                <Flex fz={12} align="center" c="dark.2">
                    <Flex pr={3}>
                        <Card p="0" w="8px" h="8px" className={classes.dot} radius="xl" />
                    </Flex>
                    <strong>Updating live:</strong>
                    <Flex fw={400} pl={4}>
                        {getCurrentWeekRange()}
                    </Flex>
                </Flex>
            </Flex>
            <ScrollArea type="never">
                <Flex direction="column">
                    <Divider />
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {isLoadingLeaderBoard ? (
                        <Flex direction="column" justify="space-between" w="188px">
                            {Array.from({ length: 12 }).map(() => (
                                <Skeleton key={Math.random().toString(33)} mb="2px" h="36px" ml={16} mr={16} />
                            ))}
                        </Flex>
                    ) : leaderBoardData && leaderBoardData.length > 0 ? (
                        <Reorder.Group as="div" values={leaderBoardData} onReorder={() => undefined}>
                            {leaderBoardData.map((info) => (
                                <Reorder.Item as="div" dragListener={false} key={info.employeeId} value={info}>
                                    <Flex
                                        key={info.employeeId}
                                        justify="space-between"
                                        fz={14}
                                        align="center"
                                        h={36}
                                        p="6px 16px 6px 16px"
                                        className={userCache.employeeId === info.employeeId ? classes.purple : ""}
                                    >
                                        <Flex>
                                            {info.order + 1}.{" "}
                                            {info.employeeId === userCache.employeeId
                                                ? "You"
                                                : splitName(info.employeeName)}
                                        </Flex>
                                        <Flex pl={20}>{info.numSales}</Flex>
                                    </Flex>
                                    <Divider />
                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                    ) : (
                        <Flex
                            direction="column"
                            mt="3rem"
                            align="center"
                            justify="center"
                            p={16}
                            fw={400}
                            c="dark.2"
                            fz={14}
                        >
                            Leaderboard data unavailable.
                        </Flex>
                    )}
                </Flex>
            </ScrollArea>
        </Flex>
    );
}
