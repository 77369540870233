import { Button, Transition } from "@mantine/core";
import { Wand } from "../assets";

interface HelpMeNowButtonProps {
    isLoading: boolean;
    shouldShow: boolean;
    onClick: () => void;
}

export function HelpMeNowButton({ isLoading, shouldShow, onClick }: HelpMeNowButtonProps) {
    return (
        <Transition duration={400} mounted={shouldShow} transition="slide-up" timingFunction="ease">
            {(transitionStyles) => (
                <Button
                    bottom="7rem"
                    color="dark.7"
                    onClick={onClick}
                    pos="absolute"
                    rightSection={<Wand />}
                    disabled={isLoading}
                    style={{ ...transitionStyles, border: ` 0.125rem solid var(--mantine-color-primary-7)` }}
                >
                    Instant Assist
                </Button>
            )}
        </Transition>
    );
}
